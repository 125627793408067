<template>
  <ul :class="menuClass">
    <li v-for="(tag, key) in tags" :key="key">
      <search-tag
        :checks.sync="tagCheck"
        :tag="getTagName(tag)"
        :val="getTagValue(tag)"
      />
    </li>
  </ul>
</template>

<script>
import SearchTag from "@/components/page/items/search/SearchTag";
export default {
  name: "SearchTagGroup",
  components: { SearchTag },
  props: {
    tags: Array,
    tagName: {
      type: String,
      default: "",
      required: false
    },
    tagValue: {
      type: [String, Number, Array, Object],
      default: "",
      required: false
    },
    checks: {
      type: [Array, Object]
    },
    menuClass: {
      type: String,
      default: "form-checkbox"
    }
  },
  computed: {
    tagCheck: {
      get() {
        return this.checks;
      },
      set(n) {
        this.$emit("update:checks", n);
      }
    }
  },
  methods: {
    getTagName(tag) {
      return this.tagName ? tag[this.tagName] : tag;
    },
    getTagValue(tag) {
      return this.tagValue ? tag[this.tagValue] : tag;
    }
  }
};
</script>

<style scoped></style>
