<template>
  <site-section :show-head="false" section-class="b-filter-items">
    <template v-slot:description>
      <div class="filter-items-flex">
        <a class="open-filter" v-if="showFilter" @click.prevent="openFilter">
          Фильтровать <span>+</span>
        </a>
        <a v-else></a>
        <div class="open-sort-wrapper">
          <a class="open-sort" @click.prevent="openSort">
            Сортировать <span>&#8249;</span>
          </a>
          <div
            :style="{
              visibility: sortVisible ? 'hidden' : 'visible',
              opacity: sortVisible ? 0 : 1,
              'z-index': 50
            }"
            class="sort-hidden"
          >
            <ul>
              <li v-for="(type, key) in sortTypes" :key="key">
                <a
                  href="#"
                  @click.prevent="
                    changeSortType(type.topic, type.dir, type.page)
                  "
                  :class="{ 'active-sort': type.topic === makeSort.topic }"
                >
                  {{ type.name }}
                  <i
                    class="fa"
                    :class="[
                      makeSort.direction === 'DESC'
                        ? 'fa-sort-amount-down'
                        : 'fa-sort-amount-up'
                    ]"
                    v-if="type.topic === makeSort.topic"
                  ></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:content v-if="showFilter">
      <div
        :style="{
          visibility: filterVisible ? 'hidden' : 'visible',
          opacity: filterVisible ? 0 : 1
        }"
        id="filter-area"
      >
        <h4 class="open-filter" @click="openFilter">
          Фильтровать <span>-</span>
        </h4>
        <div class="filter-wrapper">
          <div class="filter-area-flex">
            <div class="filter-area-flex-left">
<!--              <label class="header-label">Площадки</label>
              <search-tag-group
                :tags="filters.playgrounds"
                :checks.sync="checks.playgrounds"
              />
              <div class="clear"></div> -->
              <label class="header-label">Язык</label>
              <search-tag-group
                :tags="filters.languages"
                :checks.sync="checks.languages"
              />
              <div class="clear"></div>
            </div>
            <div class="filter-area-flex-right">
              <label class="header-label">Спикеры</label>
              <search-tag-group
                :tags="speakers"
                tag-name="name"
                tag-value="id"
                :checks.sync="checks.speakers"
              />
              <div class="clear"></div>
              <label class="header-label margin-top-label">
                Страна выпуска
              </label>
              <search-tag-group
                :tags="filters.countries"
                :checks.sync="checks.countries"
              />
              <div class="clear"></div>
            </div>
          </div>
          <div style="text-align: center">
            <button
              class="load-filter-btn"
              @click="sort(makeSort.topic, makeSort.direction, makeSort.page)"
            >
              Показать
            </button>
          </div>
          <div class="wrapper-filter-bottom" v-if="false">
            <label class="header-label">Тематика</label>
            <ul class="form-checkbox">
              <li>
                <label
                  ><input name="ploshadki" type="checkbox" /><span
                    >AmoConf</span
                  ></label
                >
              </li>
              <li>
                <label
                  ><input name="ploshadki" type="checkbox" /><span
                    >Яндекс.Конференция</span
                  ></label
                >
              </li>
              <li>
                <label
                  ><input name="ploshadki" type="checkbox" /><span
                    >Атланты</span
                  ></label
                >
              </li>
              <li>
                <label
                  ><input name="ploshadki" type="checkbox" /><span
                    >T E D</span
                  ></label
                >
              </li>
              <li>
                <label
                  ><input name="ploshadki" type="checkbox" /><span
                    >Synergy Online Global</span
                  ></label
                >
              </li>
              <li>
                <label
                  ><input name="ploshadki" type="checkbox" /><span
                    >Трансформация</span
                  ></label
                >
              </li>
              <li>
                <label
                  ><input name="ploshadki" type="checkbox" /><span
                    >ReForum</span
                  ></label
                >
              </li>
              <li>
                <label>
                  <input name="ploshadki" type="checkbox" />
                  <span>R I S E</span>
                </label>
              </li>
            </ul>
            <div class="clear"></div>
          </div>
        </div>
      </div>
    </template>
  </site-section>
</template>

<script>
import SiteSection from "@/components/page/SiteSection";
import SearchTagGroup from "@/components/page/items/search/SearchTagGroup";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "MediaFilterBlock",
  components: { SearchTagGroup, SiteSection },
  props: {
    showFilter: {
      type: Boolean,
      default: true
    },
    sortMethod: Function,
    checksObject: {
      type: Object,
      default: () => ({
        playgrounds: [],
        languages: [],
        countries: [],
        speakers: []
      })
    },
    sortTypes: {
      type: Array,
      default: () => [
        { topic: "POPULAR", dir: "DESC", page: 1, name: "По популярности" },
        { topic: "NEW", dir: "DESC", page: 1, name: "По новизне" },
        { topic: "RATING", dir: "DESC", page: 1, name: "По рейтингу" },
        { topic: "DURATION", dir: "DESC", page: 1, name: "По длительности" }
      ]
    }
  },
  data() {
    return {
      filterVisible: true,
      sortVisible: true,
      makeSort: {
        topic: "POPULAR",
        direction: "DESC",
        page: 1
      }
    };
  },
  computed: {
    ...mapGetters({
      filters: "getFilters",
      speakers: "speakers"
    }),
    checks: {
      get() {
        return this.checksObject;
      },
      set(n) {
        this.$emit("update:checksObject", n);
      }
    }
  },
  methods: {
    ...mapActions(["getFilters", "getSpeakers"]),
    openFilter() {
      this.filterVisible = !this.filterVisible;
    },
    openSort() {
      this.sortVisible = !this.sortVisible;
    },
    switchDirection(direction) {
      switch (direction) {
        case "DESC":
          return "ASC";
        default:
          return "DESC";
      }
    },
    changeSortType(topic, direction, page) {
      console.log(topic, this.makeSort.topic);
      let sort;
      if (topic === this.makeSort.topic) {
        sort = this.switchDirection(this.makeSort.direction);
      } else {
        sort = direction;
      }
      this.makeSort = {
        topic: topic,
        direction: sort,
        page: page || 1
      };
    },
    sort(topic, direction, page) {
      this.sortMethod(topic, direction, page, this.checks).then(() => {
        if (!this.filterVisible) {
          this.openFilter();
        }
        if (!this.sortVisible) {
          this.openSort();
        }
      });
    }
  },
  created() {
    if (this.showFilter) {
      this.getFilters().then(() => {
        this.getSpeakers(1);
      });
    }
  },
  watch: {
    makeSort(n, o) {
      if (o.direction !== n.direction || o.topic !== n.topic) {
        setTimeout(() => {
          this.sort(n.topic, n.direction, n.page);
        }, 500);
      }
    }
  }
};
</script>

<style scoped>
.sort-hidden a:hover {
  color: #1b55e2;
}
</style>
