<template>
  <fragment>
    <div class="wrapper-inner">
      <div class="inner-content">
        <h1 class="header-big">Медиатека</h1>
        <media-filter-block
          :checks-object.sync="checks"
          :sort-method="loadVideos"
        />
        <catalog-menu :selected.sync="selected" :categories="categories" />
        <media-block
          :additional-filter="checks"
          :media.sync="videos"
          :update-media="loadVideos"
          :sort="sort"
          :sort-type="sortType"
          :page="page"
        />
      </div>
    </div>
    <div class="clear"></div>
  </fragment>
</template>

<script>
import MediaFilterBlock from "@/components/page/media/MediaFilterBlock";
import CatalogMenu from "@/components/page/menu/CatalogMenu";
import MediaBlock from "@/components/page/media/MediaBlock";
import { WOW } from "wowjs";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "MediaLibrary",
  data() {
    return {
      page: 1,
      sort: "POPULAR",
      sortType: "DESC",
      selected: this.$route.params.category || 0,
      checks: {
        playgrounds: [],
        languages: [],
        countries: [],
        speakers: []
      }
    };
  },
  components: { MediaBlock, CatalogMenu, MediaFilterBlock },
  computed: {
    ...mapGetters({
      videos: "videosTest",
      categories: "categories"
    })
  },
  methods: {
    ...mapActions(["getPageVideosWithFilter", "getCategories"]),
    loadVideos(sort, direction, page, checks) {
      if (sort && direction) {
        this.sort = sort;
        this.sortType = direction;
      }
      if (page) {
        this.page = page;
      }
      return this.getPageVideosWithFilter({
        type: "videosTest",
        method: "/video/list",
        params: {
          page: this.page,
          sort: this.sort,
          sortType: this.sortType,
          categoryFilter: this.selected,
          ...(checks
            ? {
                playgroundFilter: checks.playgrounds,
                speakerFilter: checks.speakers,
                countryFilter: checks.countries,
                languageFilter: checks.languages
              }
            : {})
        }
      });
    }
  },
  mounted() {
    new WOW({ offset: 140, live: false }).init();
  },
  created() {
    this.getCategories().then(this.loadVideos);
  },
  watch: {
    selected() {
      this.loadVideos(this.sort, this.sortType, 1);
    }
  }
};
</script>

<style scoped></style>
