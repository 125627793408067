<template>
  <show-more-block
    :show-more="showMore"
    :section-class="blockClass"
    :head-name="blockHead"
    :update-list="updateMedia"
    :sort="sort"
    :size="size"
    :page="page"
    :sort-type="sortType"
    :init-size="media.length"
    :additional-filter="additionalFilter"
  >
    <video-tiles :tiles="media" />
  </show-more-block>
</template>

<script>
import VideoTiles from "@/components/page/video/VideoTiles";
import ShowMoreBlock from "@/components/page/items/list/ShowMoreBlock";
export default {
  name: "MediaBlock",
  components: { ShowMoreBlock, VideoTiles },
  props: {
    blockHead: {
      type: String,
      default: ""
    },
    additionalFilter: {
      type: Object,
      required: false
    },
    blockClass: {
      type: String,
      default: "news-mediateka-inner"
    },
    page: {
      type: Number,
      default: 1
    },
    media: Array,
    showMore: {
      type: Boolean,
      default: true
    },
    size: {
      type: Number,
      default: 10
    },
    updateMedia: Function,
    sort: {
      type: String,
      default: ""
    },
    sortType: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped></style>
