<template>
  <div class="inner-news-tovar" v-if="tiles && tiles.length">
    <video-tile
      v-for="tile in tiles"
      :is-to-card="true"
      :data="tile"
      :fav-remove="useFavouriteRemove"
      v-bind:key="tile.key"
    />
  </div>
  <div class="inner-news-tovar center" v-else>
    <div>
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <!--         <div v-if="show_loader == false">
          <h5>Видео не найдены</h5>
      </div> -->
  </div>
</template>

<script>
import VideoTile from "@/components/page/video/VideoTile";
export default {
  name: "VideoTiles",
  components: { VideoTile },
  props: {
    tiles: Array,
    useFavouriteRemove: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show_loader: true
    };
  },
  mounted() {
    if (this.tiles.length == 0) {
      this.show_loader = true;
      this.showToggle();
    } else {
      this.showToggle();
    }
  },
  methods: {
    showToggle() {
      setTimeout(() => {
        this.show_loader = false;
      }, 1500);
    }
  }
};
</script>

<style scoped>
.center {
  text-align: center;
  margin: auto;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
